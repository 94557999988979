import {post} from "./ajax";
import {openModalFromHtml} from "./modal";
import {slideDown} from "./element-visibility";
import {openOffcanvasFromHtml} from "./offcanvas";

export function getCookie(name)
{
    name = name + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0)==' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length,c.length);
        }
    }
    return null;
}

export function setCookie(name, value, exDays)
{
    let d = new Date();
    if (areCookiesAccepted()) {
        d.setTime(d.getTime() + (exDays * 24 * 60 * 60 * 1000));
    } else {
        d.setTime(d.getTime() + (exDays * -1000));
    }
    let expires = "expires="+ d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

export function deleteCookie(name){
    let d = window.location.hostname.split(".");
    document.cookie = name+"=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = name+"=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain="+d[1]+'.'+d[2]+";";
}

export function deleteAllCookies(){
    let cookies = document.cookie.split("; ");
    for (let c = 0; c < cookies.length; c++) {
        deleteCookie(cookies[c].split(";")[0].split("=")[0]);
    }
}

export function showModalCookies()
{
    let cookielessPage = document.querySelector('.cookieless-page')
    if (!cookielessPage) {
        let cookieConsent = getCookie('_tv_cookie_consent');
        let cookieCheckout = getCookie('_tv_in_checkout');

        if (!cookieConsent && !cookieCheckout) {
            getModalCookies(function (html) {
                openModalFromHtml(html, {}, true);
            });
        }
    }
}
export function showOffcanvasCookies()
{
    let cookielessPage = document.querySelector('.cookieless-page');
    let cookieConsent = getCookie('_tv_cookie_consent');
    let cookieCheckout = getCookie('_tv_in_checkout');

    if (cookieConsent || cookieCheckout) return;

    if (!cookielessPage) {
        getOffcanvasCookies(function (html) {
            openOffcanvasFromHtml(html, {}, true);
        });
    }
}

export function getModalCookies(callback)
{
    post(
        '/ajax/website/cookies/get-modal-cookies',
        {},
        function(data){
            callback(data.html);
        }
    );
}
export function getOffcanvasCookies(callback)
{
    post(
        '/ajax/website/cookies/get-offcanvas-cookies',
        {},
        function(data){
            callback(data.html);
        }
    );
}

export function showCookiesMessage()
{
    let cookieConsent = getCookie('_tv_cookie_consent');
    let cookieCheckout = getCookie('_tv_in_checkout');
    if (!cookieConsent && !cookieCheckout) {
        let div = document.getElementById('cookies-message');
        getCookiesMessageHtml(function (html) {
            div.innerHTML = html;
            slideDown(div);
        });
    }
}

export function getCookiesMessageHtml(callback)
{
    post(
        '/ajax/website/header/get-cookies-message-html',
        {},
        function(data){
            callback(data.html);
        }
    );
}

/**
 * DEPRECATED
 */
export function setCookiesDefault()
{
    setCookie('_tv_cookie_choice', 'ALL', 90);
    if (window.gtag) {
        gtag('consent', 'update', {
            'ad_storage': 'granted',
            'analytics_storage': 'granted',
            'ad_user_data': 'granted',
            'ad_personalization': 'granted'
        });
    }
}

export function onPageHideCookieListener()
{
    if (!areCookiesAccepted()) localStorage.removeItem('_tv_cookie_choice');
}

export function acceptAllCookies(days, eventName = 'cookies')
{
    if (localStorage.getItem('ga:clientId')) localStorage.removeItem('ga:clientId');
    if (!areCookiesAccepted()) localStorage.removeItem('_tv_cookie_choice');
    setCookie('_tv_cookie_consent', 'y', days);
    setCookie('_tv_cookie_choice', 'ALL', days);
    if (window.gtag) {
        gtag('consent', 'update', {
            'ad_storage': 'granted',
            'analytics_storage': 'granted',
            'ad_user_data': 'granted',
            'ad_personalization': 'granted'
        });
    }
    if (window.fbq) {
        fbq('consent', 'grant');
    }
    window.removeEventListener('pagehide', onPageHideCookieListener);
    dataLayer.push({
        event: eventName || 'cookies',
        method: 'accept_all'
    });
    //sendExperimentEvent();
}

export function acceptAnalyticsCookies(days)
{
    if (!areCookiesAccepted()) localStorage.removeItem('_tv_cookie_choice');
    setCookie('_tv_cookie_consent', 'y', days);
    let cookieChoice = 'ANALYTICS';
    if (getCookie('_tv_cookie_choice')) {
        cookieChoice = getCookie('_tv_cookie_choice')+'+'+cookieChoice;
    }
    setCookie('_tv_cookie_choice', cookieChoice, days);
    if (window.gtag) {
        gtag('consent', 'update', {
            'analytics_storage': 'granted'
        });
    }
    if (window.fbq) {
        fbq('consent', 'revoke');
    }
    //sendExperimentEvent();
    window.removeEventListener('pagehide', onPageHideCookieListener);
}

export function acceptMarketingCookies(days)
{
    if (!areCookiesAccepted()) localStorage.removeItem('_tv_cookie_choice');
    setCookie('_tv_cookie_consent', 'y', days);
    let cookieChoice = 'MARKETING';
    if (getCookie('_tv_cookie_choice')) {
        cookieChoice = getCookie('_tv_cookie_choice')+'+'+cookieChoice;
    }
    setCookie('_tv_cookie_choice', cookieChoice, days);
    if (window.gtag) {
        gtag('consent', 'update', {
            'ad_storage': 'granted',
            'ad_user_data': 'granted',
            'ad_personalization': 'granted'
        });
    }
    if (window.fbq) {
        fbq('consent', 'grant');
    }
    window.removeEventListener('pagehide', onPageHideCookieListener);
}

export function acceptBasicCookies(days)
{
    if (!areCookiesAccepted()) localStorage.removeItem('_tv_cookie_choice');
    setCookie('_tv_cookie_consent', 'y', days);
    let cookieChoice = 'BASIC';
    if (getCookie('_tv_cookie_choice')) {
        cookieChoice = getCookie('_tv_cookie_choice')+'+'+cookieChoice;
    }
    setCookie('_tv_cookie_choice', cookieChoice, days);
    window.removeEventListener('pagehide', onPageHideCookieListener);
}

export function acceptNoCookies()
{
    deleteAllCookies();
    localStorage.setItem('_tv_cookie_choice', 'none');
    /*if (window.gtag) {
        gtag('consent', 'update', {
            'ad_storage': 'denied',
            'analytics_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied'
        });
    }*/
}

export function areCookiesAccepted()
{
    return !localStorage.getItem('_tv_cookie_choice');
}

export function areAllCookiesAccepted()
{
    return getCookie('_tv_cookie_choice') && getCookie('_tv_cookie_choice').includes('ALL');
}

export function areAnalyticsCookiesAccepted()
{
    return areAllCookiesAccepted() ||
        (getCookie('_tv_cookie_choice') && getCookie('_tv_cookie_choice').includes('ANALYTICS'));
}

export function areMarketingCookiesAccepted()
{
    return areAllCookiesAccepted() ||
        (getCookie('_tv_cookie_choice') && getCookie('_tv_cookie_choice').includes('MARKETING'));
}

export function areBasicCookiesAccepted()
{
    return areAllCookiesAccepted() ||
        (getCookie('_tv_cookie_choice') && getCookie('_tv_cookie_choice').includes('BASIC'));
}

export function showModalConfigCookies()
{
    post(
        '/ajax/website/cookies/get-new-modal-config-cookies',
        {},
        function(data) {
            openModalFromHtml(data.html);
            dataLayer.push({
                event: 'cookies',
                method: 'show_configuration'
            });
        }
    );
}

export function showModalOldConfigCookies()
{
    post(
        '/ajax/website/cookies/get-modal-config-cookies',
        {},
        function(data) {
            openModalFromHtml(data.html);
        }
    );
}

function sendExperimentEvent()
{
    if (/bot|google|baidu|bing|msn|teoma|slurp|yandex/i.test(navigator.userAgent)) return;
    dataLayer.push({
        event: 'experience_impression',
        exp_variant_string: 'TVN-TESTLISTCTA-A'
    });
}