import {post} from "./ajax";

export function trackEvent(category, action, label, callback)
{
    if (false && gaLoaded) {
        ga('send', 'event', category, action, label, {
                hitCallback: function() {
                    if (callback) callback();
                }
            }
        );
    } else {
        if (callback) callback();
    }
}

export function ga4TrackSimpleEvent(data, callback)
{
    dataLayer.push(data);

    if (callback) callback();
}

export function ga4TrackLinkEvent(element, callback)
{
    let data = {
        'event': element.dataset.eventName ? element.dataset.eventName : 'link',
        'link_id': element.dataset.linkId ? element.dataset.linkId : 'simple-link',
        'link_text': element.getAttribute('title') ? element.getAttribute('title').toLowerCase() : null,
        'link_url': element.getAttribute('href') ? element.getAttribute('href') : null
    }
    if (element.dataset.method) {
        data['method'] = element.dataset.method;
    }

    dataLayer.push(data);

    if (callback) callback();
}

export function ga4StickerFinderTrackEvent(category, action, label)
{
    let data = {
        "event": '',
        "method": ''
    };
    if (label === 'Open') {
        data.event = 'sticker_finder_open';
        let method = null;
        if (action === 'Click Label') {
            data.method = 'label';
        } else if (action === 'Click Banner List') {
            data.method = 'banner list';
        } else if (action === 'Click Banner Home') {
            data.method = 'banner home';
        }
        if (typeof (Storage) !== 'undefined') {
            sessionStorage.setItem('stickerFinderMethod', data.method);
        }
    } else if (action === 'Click Result') {
        data.event = 'sticker_finder_select';
        data.product_id = label.reference || '';
        data.product_name = label.name || '';
        if (typeof (Storage) !== 'undefined') {
            data.method = sessionStorage.getItem('stickerFinderMethod');
            data.content_type = sessionStorage.getItem('stickerFinderContentType');
        }
    } else if (label === 'Close') {
        data = null;
        if (typeof (Storage) !== 'undefined') {
            sessionStorage.removeItem('stickerFinderMethod');
            sessionStorage.removeItem('stickerFinderContentType');
        }
    } else if (label === 'Start Now' || label === 'Back' || label === 'Restart') {
        data = null;
    } else {
        data.event = 'sticker_finder_question';
        data.link_text = action.toLowerCase();
        data.content_type = label.toLowerCase();
        if (typeof (Storage) !== 'undefined') {
            data.method = sessionStorage.getItem('stickerFinderMethod');
            sessionStorage.setItem('stickerFinderContentType', data.content_type);
        }
    }
    if (data) {
        dataLayer.push(data);
    }
}

export function ga4TrackEvent(element, callback)
{
    const eventDictionary = {
        'ClickProduct': 'select_item',
        'Share': 'share',
        'Header': 'header',
        'Footer': 'footer',
        'ShowFilters': 'filter',
        'HideFilters': 'filter',
        'ClearFilters': 'filter',
        'Filter': 'filter',
        //'PersonalizedServicesBanner': 'view_personalized',
        'ViewPersonalized': 'view_personalized',
        'ViewMore': 'view_more',
        'Color': 'select_color',
        'MaterialColor': 'select_material_color',
        'Related': 'select_item',
        'Menu': 'menu',
        'ShoppingCart': 'cart',
        'ProductList': 'product_list',
        'ProductPage': 'product_page_banner',
        'list_banner': 'list_banner',
    };

    let category = element.dataset.analyticsCategory;
    let action = element.dataset.analyticsAction;
    let label = element.dataset.analyticsLabel;
    let extraData = (element.dataset.analyticsExtra ? element.dataset.analyticsExtra : null);

    if (category === 'Related' && action === 'Click') {
        action = 'ClickProduct';
    }

    if(eventDictionary.hasOwnProperty(action) || eventDictionary.hasOwnProperty(category)) {
        if (extraData) {
            extraData = JSON.parse(extraData);
        }
        if (eventDictionary[action] === 'select_item') {
            dataProductPush(eventDictionary[action], extraData);
        } else {
            let data = {};
            if (eventDictionary[action] === 'filter') {
                let method = '';
                let contentType = null;
                if (action === 'ShowFilters') {
                    method = 'open';
                } else if (action === 'HideFilters') {
                    method = 'close';
                } else if (action === 'ClearFilters') {
                    method = 'clear';
                } else if (action === 'Filter') {
                    method = element.checked ? 'select' : 'unselect';
                    contentType = label;
                }
                data = {
                    "event": eventDictionary[action],
                    "method": method
                };
                if (contentType) {
                    data['content_type'] = contentType;
                }
            } else if (eventDictionary[action] === 'share') {
                data = {
                    "event": eventDictionary[action],
                    "method": label,
                    "content_type": "product",
                    "item_id": extraData.ref
                };
            } else if (eventDictionary[action] === 'select_color') {
                let richCard = document.querySelector('script[type="application/ld+json"]');
                let sku = '';
                let name = '';
                if (richCard) {
                    let jsonld = JSON.parse(richCard.innerText);
                    sku = jsonld ? jsonld.sku || '' : '';
                    name = jsonld ? jsonld.name || '' : '';
                } else {
                    let popup = document.getElementById('product-popup');
                    sku = popup ? popup.dataset.productReference : '';
                    name = popup ? popup.dataset.productName : '';
                }
                data = {
                    "event": eventDictionary[action],
                    "color": label,
                    "product_id": sku,
                    "product_name": name
                };
            } else if (eventDictionary[action] === 'select_material_color') {
                let richCard = document.querySelector('script[type="application/ld+json"]');
                let sku = '';
                let name = '';
                if (richCard) {
                    let jsonld = JSON.parse(richCard.innerText);
                    sku = jsonld ? jsonld.sku || '' : '';
                    name = jsonld ? jsonld.name || '' : '';
                } else {
                    let popup = document.getElementById('product-popup');
                    sku = popup ? popup.dataset.productReference : '';
                    name = popup ? popup.dataset.productName : '';
                }
                data = {
                    "event": eventDictionary[action],
                    "material_color": label,
                    "product_id": sku || '',
                    "product_name": name || ''
                };
            } else if (eventDictionary[action] === 'view_personalized' || eventDictionary[action] === 'view_more') {
                data = {
                    "event": eventDictionary[action],
                    "link_text": label
                };
            } else if (eventDictionary[category] === 'header' || eventDictionary[category] === 'footer') {
                data = {
                    "event": eventDictionary[category],
                    "link_id": action,
                    "link_text": label,
                    "link_url": element.getAttribute('href') ? element.getAttribute('href') : ''
                };
            } else if (eventDictionary[category] === 'menu' || eventDictionary[action] === 'menu') {
                let method = (label === 'Categoría') ? 'category' : label.toLowerCase();
                data = {
                    "event": 'menu',
                    "link_id": element.dataset.analyticsId ? element.dataset.analyticsId : '-',
                    "link_text": element.getAttribute('title') ? element.getAttribute('title') : null,
                    "link_url": element.getAttribute('href') ? element.getAttribute('href') : null,
                    "method": method
                };
            } else if (eventDictionary[category] === 'cart') {
                data = {
                    "event": eventDictionary[category],
                    "method": 'close'
                };
            } else if (eventDictionary[category] === 'product_list') {
                if (action === 'CategoryCombination') {
                    data = {
                        "event": 'product_list',
                        "method": 'double_category',
                        'content_type': element.dataset.combinationName ? element.dataset.combinationName : '-'
                    };
                } else if (action === 'Pagination') {
                    let listType = document.querySelector('.product-list.search-results') ? 'search_results' : 'product_list';
                    data = {
                        "event": listType,
                        "method": 'pagination',
                        'content_type': label || '-'
                    };
                } else if (action === 'PersonalizedServicesBannerList') {
                    data = {
                        "event": 'search_results',
                        "method": 'personalised_service_banner',
                        'content_type': label || '-'
                    };
                } else if (action === 'PersonalizedServicesBanner') {
                    let listType = document.querySelector('.product-list.search-results') ? 'search_results' : 'product_list';
                    data = {
                        "event": listType,
                        "method": 'personalised_service_banner_footer',
                        'content_type': label || '-'
                    };
                }
            } else if (eventDictionary[category] === 'product_page_banner') {
                if (action === 'PersonalizedServicesBanner') {
                    data = {
                        "event": 'product_page',
                        "method": 'personalised_service_banner_footer',
                        'content_type': label || '-'
                    };
                }
            } else if (eventDictionary[category] === 'list_banner') {
                let ref = element.dataset.ref ? element.dataset.ref : '-';
                let position = element.dataset.position ? element.dataset.position : '-';
                let id = element.dataset.id ? element.dataset.id : '-';

                if (ref === 'lateua') {
                    ref = element.getAttribute('href') ? element.getAttribute('href') : null;
                }
                if (typeof (Storage) !== 'undefined') {
                    sessionStorage.setItem('selectedPromotionId', id);
                    sessionStorage.setItem('selectedPromotionName', ref);
                }
                dataLayer.push({ecommerce: null});
                dataLayer.push({
                    event: 'select_promotion',
                    ecommerce: {
                        items: {
                            promotion_id: id,
                            promotion_name: ref,
                            creative_name: element.getAttribute('title') ? element.getAttribute('title') : null,
                            creative_slot: position,
                        }
                    }
                });

                data = {
                    "event": 'list_banner',
                    "link_id": element.dataset.list ? element.dataset.list : '-',
                    "link_text": element.getAttribute('title') ? element.getAttribute('title') : null,
                    "link_url": element.getAttribute('href') ? element.getAttribute('href') : null
                };

            }
            if (data) {
                dataLayer.push(data);
            }
        }
    }

    if (callback) callback();
}

export function itemDatalayerPush(eventName, element)
{
    let extraData = (element.dataset.analyticsExtra ? element.dataset.analyticsExtra : null);
    if (extraData) {
        extraData = JSON.parse(extraData);
        dataProductPush(eventName, extraData);
    }
    element.classList.remove('fire-product-datalayer');
}

function dataProductPush(eventName, extraData)
{
    let selectedPromotionId = null;
    let selectedPromotionName = null;
    if (typeof (Storage) !== 'undefined') {
        if (eventName === 'select_item') {
            selectedPromotionId = sessionStorage.getItem('selectedPromotionId');
            selectedPromotionName = sessionStorage.getItem('selectedPromotionName');
            if (selectedPromotionId && selectedPromotionName) {
                sessionStorage.setItem('selectedProductFromPromoId-' + extraData['ref'], selectedPromotionId);
                sessionStorage.setItem('selectedProductFromPromoName-' + extraData['ref'], selectedPromotionName);
            }
            sessionStorage.removeItem('selectedPromotionId');
            sessionStorage.removeItem('selectedPromotionName');
        }
    }
    let data = {
        items: [
            {
                item_id: extraData['ref'],
                item_name: extraData['internalName'] || extraData['name'],
                index: extraData['listPosition'] || 0,
                quantity: extraData['quantity'] || 1,
                price: extraData['price'] || 0,
                discount: extraData['discount'] || 0,
                item_brand: extraData['brand'] || '',
                promotion_id: selectedPromotionId,
                promotion_name: selectedPromotionName,
            }
        ]
    };
    if (extraData['listName']) {
        data['item_list_name'] = extraData['listName'];
    }
    if (extraData['listType']) {
        data['item_list_id'] = extraData['listType'];
    }

    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: eventName,
        ecommerce: data
    });
}

export function dataLayerAddProduct(item, remove) {
    remove = remove || false;
    dataLayer.push({ ecommerce: null });
    dataLayer.push({ dynamicRemarketingData: null });
    dataLayer.push({
        event: !remove ? "add_to_cart" : "remove_from_cart",
        ecommerce: {
            items: [
                {
                    item_id: item.reference,
                    item_name: item.name,
                    item_brand: item.brand || null,
                    currency: item.currency,
                    //discount: 2.22,
                    //item_variant: item.color,
                    price: item.price,
                    quantity: item.quantity < 0 ? item.quantity * -1 : item.quantity
                }
            ]
        },
        dynamicRemarketingData: {
            value: item.price,
            items: [
                {
                    id: item.reference,
                    google_business_vertical: 'retail'
                }
            ]
        }
    });
}

export function ecAddProduct(id, name, price, quantity, color, size, orientation) {
    return;
    let productObject = {
        'id': id,
        'name': name
    };
    if (price) {
        productObject['price'] = price;
    }
    if (quantity) {
        productObject['quantity'] = quantity;
    }
    if (color) {
        productObject['variant'] = color;
    }
    if (size) {
        productObject['dimension1'] = size;
    }
    if (orientation) {
        productObject['dimension2'] = orientation;
    }
    window.ga('ec:addProduct', productObject);
}

export function ecSetAction(action, params) {
    return;
    if (params) {
        window.ga('ec:setAction', action, params);
    } else {
        window.ga('ec:setAction', action);
    }
}

export function trackLinkEvent(category, action, label, link, event)
{
    let target = (!link.attr('target') ? "_self" : link.attr('target'));
    if (event.which !== 1 || event.ctrlKey) target = "_blank";
    trackEvent(category, action, label);
    if (event.which === 3) return;
    openLink(link, target);
}

export function openLink(link, target)
{
    window.open(link.href, target);
}

export function getCid()
{
    let cid = '-';
    if (getCookie('_tv_uuid')) {
        let uuid = getCookie('_tv_uuid');
        if (uuid.indexOf('_') !== -1) {
            uuid = uuid.split('_');
            cid = uuid[1];
        } else {
            cid = uuid;
        }
    } else if (getCookie('_ga')) {
        let ga = getCookie('_ga');
        ga = ga.split('.');
        cid = ga[2]+'.'+ga[3];
    }

    return cid.length ? cid : '-';
}

export function sendFacebookConversionsEvent(event, payload)
{
    post(
        '/_api/fb/conversions_event',
        {
            event: event,
            payload: payload
        }
    );
}